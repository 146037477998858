<template>
    <router-view/>
</template>

<script>

    export default {
        name: 'Home',
        data() {
            return {
                pageTitle: 'Interventies'
            }
        }
    }
</script>
